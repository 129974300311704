<template>
  <div>
    <h3>My Profile</h3>
    <div class="dashboard-wraper" v-if="! loading">
      <form @submit.prevent="submit">
        <div class="row mb-3">
          <div class="col-md-3">
            <img :src="user.avatar" :alt="user.name" style="max-height: 100px; max-width: 100%;">
          </div>
          <div class="col-md-9">
            <label>Profile Picture</label>
            <input type="file" ref="profile_picture" placeholder="Profile Picture" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label>Name</label>
            <input type="text" placeholder="Full Name" v-model="user.name" class="form-control">
          </div>
          <div class="col-md-6 mb-3">
            <label>Email</label>
            <input type="email" placeholder="Email" readonly v-model="user.email" class="form-control">
          </div>
          <div class="col-md-6 mb-3">
            <label>Phone Number</label>
            <input type="text" placeholder="Phone Number" v-model="user.phone_number" class="form-control">
          </div>
          <div class="col-md-12 text-end">
            <button class="btn btn-primary"><i class="lni lni-save me-2"></i>Save</button>
          </div>
        </div>
      </form>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    }
  },

  methods: {
    submit() {
      this.loading = true
      let data = new FormData
      data.append('name', this.user.name)
      data.append('phone_number', this.user.phone_number)

      let image = this.$refs.profile_picture.files[0]

      if(image) {
        data.append('avatar', image)
      }

      this.$axios.post('/api/v1/my-account/profile', data).then(response => {
        this.loading = false
        this.$store.dispatch('setAuthUser', response.data.user)
        this.$swal('Your profile has been updated.', '', 'success')
      })
    }
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
}
</script>